import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Navbar,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav
} from 'reactstrap';
import TranslateMessage from '../common/TranslateMessage/TranslateMessage';
import { AccountCircle, Person, PersonAdd, ExitToApp, LocalLibrary, VpnKey, Subscriptions, Menu, Search } from '@material-ui/icons';
import { getAuthUser, getIsMobileApp, isAuthenticated, isGoogleLogin } from 'state/session/sessionSelector';
import { getPageTitle, getPageLogo, getPageFavicon, getBannerSetting, getLayoutSettingAll } from 'state/search/searchSelector';
import { ActionContext } from '../layout/MainContainer';
import LoginModal from 'components/modal/LoginModal';
import LoginEndUserForm from 'views/end-user/form/LoginEndUserForm';
import md5 from 'md5';
import { APP_URL, AUTHEN_TYPES, BANNER_TYPE, DOMAIN_TYPE, IMAGE_PATH, LAYOUT_SETTING, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';
import Util from 'utils/Util';
import { makeStyles, Box, Grid } from '@material-ui/core';
// import notFoundImage from '../../assets/img/not-found.png';
import { useMedia } from 'react-use';
import { getPageMargin, getServicePath, getViewSettings } from 'state/company/companySelector';
import IconMenuBarButton from 'components/common/Button/IconMenuBarButton';
import CarouselHeader from 'components/common/Carousel/CarouselHeader';
import { useIntl } from 'react-intl';

const WIDTH_SLIDE = 948;

const HomeHeader = (props) => {
  const {
    sessionActions: { logout, login },
    // companyActions: { loadGakkenTerms },
    authUser,
    pageTitle,
    pageLogo,
    pageFavicon,
    isAuthen,
    toggleSidebar = () => {},
    showLoginFormModal,
    setShowLoginFormModal,
    bannerSetting,
    showSidebar,
    setShowTabSearch = () => {},
    isContentDetail,
    layoutViewPage,
    isNotShowBanner,
    setCacheSearchRequest,
    setSearchRequest,
    setFulltextSearch,
    setSearchRequestFullTextSearch,
    clearContents,
    isMobileApp
  } = props;
  const { contentId } = useParams();
  const style = useSelector((state) => state.search.headerSetting) || {};
  const showSubscriptionStatus = useSelector((state) => state.company.showSubscriptionStatus);
  const viewSettings = useSelector(getViewSettings);
  const layoutSettingAll = useSelector(getLayoutSettingAll) || {};
  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const servicePath = useSelector(getServicePath);
  const pageMargin = useSelector(getPageMargin);

  const { formatMessage: f } = useIntl();

  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(40);

  const isFullDomain = viewSettings.domainType === DOMAIN_TYPE.FULL_DOMAIN;

  const redirectUrl = isFullDomain ? `${window.location.origin}/login` : `${window.location.origin}/${servicePath}/login`;
  const isLayout3 = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;
  const below768 = useMedia('(max-width: 768px)');
  // const [selectedItem, setSelectedItem] = useState(0);

  const useStyle = makeStyles(() => (
    {
      header: {
        flexWrap: 'nowrap',
        backgroundColor: `${style.backgroundColor}!important`,
        color: style.color || '#d0d0d0',
        padding: '16px 20.8px',
        height: below768 ? '80px' : 'unset',
        width: '100%',
        '& .dropdown-toggle:after': {
          display: 'none'
        },
        '& .nav-link': {
          color: `${style.color || '#d0d0d0'}!important`,
          whiteSpace: 'unset',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        }
      },
      headerItem: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        padding: '0 0.5rem',
        color: style.color || '#d0d0d0',
        cursor: 'pointer'
      },
      headerItem2: {
        display: 'flex',
        flexFlow: 'column nowrap',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        padding: '0 0.5rem',
        color: style.color || '#d0d0d0',
        cursor: 'pointer',
        flexDirection: 'row'
      },
      headerItem3: {
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        padding: '0 0.5rem',
        color: style.color || '#d0d0d0',
        cursor: 'pointer'
      },
      logo: {
        width: 'auto',
        height: '100%',
        minHeight: below768 ? 'unset' : '60px',
        maxWidth: below768 ? '70px' : 'unset',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      logoBox: {
        display: 'flex',
        alignItems: 'center'
      },
      pageTitle: {
        margin: '0 10px!important',
        padding: 0,
        color: `${style.color || '#fff'}!important`,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        maxWidth: below768 ? 'calc(100vw - 170px)' : 'calc(100vw - 470px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.25rem',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      wrapControl: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      bannerBox: {
        height: bannerHeight,
        backgroundColor: bannerSetting.backgroundColor,
        color: bannerSetting.textColor,
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        fontSize: below768 ? bannerSetting.fontSize * 0.6 : bannerSetting.fontSize,
        cursor: 'pointer',
        '& img': {
          objectFit: bannerSetting.typeBanner === BANNER_TYPE.SLIDE ? '' : 'contain',
          height: '100%',
          width: '100%'
        },
        wordBreak: 'break-word',
        textAlign: 'center',
        padding: bannerSetting.typeBanner === 1 ? '0px 10px 0px 10px' : ''
      },
      layout3: {
        marginLeft: `${!below768 ? pageMargin.left : '0'}px`,
        marginRight: `${!below768 ? pageMargin.right : '0'}px`
      },
      // iconCheck: {
      //   color: '#00B27B',
      //   position: 'absolute',
      //   right: 3,
      //   bottom: -2,
      //   fontWeight: 'bold',
      //   fontSize: 24
      // },
      accountCircleActive: {
        color: '#00B27B'
      },
      iconUserCircle: {
        fontSize: 25
      },
      userName: {
        maxWidth: 'calc(100% - 30px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: 4
      },
      containerSlideItem: {
        width: '100%',
        height: bannerHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      slideItem: {
        width: WIDTH_SLIDE,
        // minWidth: 50,
        height: bannerHeight
      },
      imgCarouselItem: {
        width: '100%',
        height: bannerHeight,
        borderRadius: '9px'
      },
      iconMenu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      fullWidth: {
        width: '100%'
      },
      customCarouse: {
        '& .carousel .control-dots': {
          // zIndex: bannerHeight <= 60 ? -1 : 1
          margin: bannerHeight <= 60 ? below768 ? '0' : '-5px 0' : '10px 0'
        }
      }
    }
  ));

  const classes = useStyle();

  const history = useHistory();
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';
  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);

  const handleLoginFormSubmit = async (data, authenType) => {
    setShowLoginFormModal(false);
    let request;
    if (authenType === AUTHEN_TYPES.EMAIL) {
      request = {
        password: md5(data.password),
        rememberMe: data.rememberMe,
        email: data.email,
        loginType: AUTHEN_TYPES.EMAIL,
        loginRole: USER_CONFIG.USER.loginType,
        path: data.path
      };
    } else if (authenType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      request = data;
    } else {
      const userTrace = await Util.getUserTrace();
      const ip = userTrace.ip;
      request = {
        ip: ip,
        loginType: AUTHEN_TYPES.IP
      };
    }
    login(request);
  };

  const handleLogout = () => {
    if (!isMobileApp) {
      logout();
    }
    setShowTabSearch(false);
  };
  const openLinkBanner = () => {
    if (bannerSetting.link && bannerSetting.link !== '') {
      window.open(bannerSetting.link);
    }
  };

  const handleClickSlide = (slide) => {
    if (slide.bannerSlideLinkType === 'EXTERNAL_LINK') {
      if (slide.bannerSlideLink !== '') {
        window.open(slide.bannerSlideLink);
      }
    }
    if (slide.bannerSlideLinkType === 'INTERNAL_LINK') {
      if (slide.bannerSlideLink !== '') {
        history.push(`${slide.bannerSlideLink}`);
      }
    }
  };

  const renderCarouselItem = (list) => {
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      const image = below768 ? list[i].mobileSlideImage : list[i].desktopSlideImage;
      if (!image || image === '') {
        continue;
      }
      const item = (
        <div className={classes.containerSlideItem} onClick={() => { handleClickSlide(list[i]) }}>
          <div className={classes.slideItem}>
            <img className={classes.imgCarouselItem} src={APP_URL.UPLOAD_PATH + '/' + IMAGE_PATH.BANNER + image} alt="slide" />
          </div>
        </div>
      );
      arr = [...arr, item];
    }
    return arr;
  };

  const renderBanner = () => {
    if (
      !window.location.pathname.includes('profile') &&
      !window.location.pathname.includes('notifications') &&
      !window.location.pathname.includes('subscription') &&
      !window.location.pathname.includes('search') &&
      !window.location.pathname.includes('order-entry') &&
      !window.location.pathname.includes('gakken-payment') &&
      !window.location.pathname.includes('veritrans-payment') &&
      !window.location.pathname.includes('thank-you') &&
      !contentId
    ) {
      if (bannerSetting && bannerSetting.show) {
        switch (bannerSetting.typeBanner) {
          case BANNER_TYPE.MESSAGE:
            if (bannerSetting.message && bannerSetting.message !== '') {
              return <Box className={classes.bannerBox} onClick={() => openLinkBanner()}>
                {bannerSetting.message}
              </Box>;
            } else {
              return null;
            }
          case BANNER_TYPE.IMAGE:
            if (bannerSetting.image && bannerSetting.image !== '') {
              return <Box className={classes.bannerBox} onClick={() => openLinkBanner()}>
                <img src={`${APP_URL.UPLOAD_PATH}/${IMAGE_PATH.BANNER}${bannerSetting.image}`} alt="banner" />
              </Box>;
            } else {
              return null;
            }
          case BANNER_TYPE.SLIDE:
            if (bannerSetting.bannerImageResponseList && bannerSetting.bannerImageResponseList.length > 0) {
              return (
                <Box className={classes.bannerBox}>
                  <CarouselHeader items={renderCarouselItem(bannerSetting.bannerImageResponseList)} onChange={() => {}}/>
                </Box>);
            } else {
              return null;
            }
          default:
            return null;
        }
      }
    }
  };

  const registerGakkenUser = async () => {
    const gakkenUrl = `${process.env.REACT_APP_GAKKEN_LINK}/user-register-1?response_type=code&client_id=${viewSettings.gakkenServiceCode}&redirect_uri=${redirectUrl}`;
    window.location.replace(gakkenUrl);
  };
  const handleClickOutside = (event) => {
    const accountDropdownToggle1 = document.getElementById('accountDropdownToggle1');
    const accountDropdownToggle2 = document.getElementById('accountDropdownToggle2');
    if (accountDropdownToggle1 && !accountDropdownToggle1.contains(event.target)) {
      setIsOpenProfile(false);
    }
    if (accountDropdownToggle2 && !accountDropdownToggle2.contains(event.target)) {
      setIsOpenProfile(false);
    }
    if (!accountDropdownToggle1 && !accountDropdownToggle2) {
      setIsOpenProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (bannerSetting.typeBanner === BANNER_TYPE.SLIDE) {
      const bannerDesktop = bannerSetting.heightBanner ? bannerSetting.heightBanner : 329;
      const bannerMobile = bannerSetting.mobileBannerHeight ? bannerSetting.mobileBannerHeight : 256;
      setBannerHeight(below768 ? Util.mobileCheck() ? bannerMobile : bannerDesktop : bannerDesktop);
      return;
    }
    setBannerHeight(below768 ? Util.mobileCheck() ? bannerSetting.mobileBannerHeight : bannerSetting.heightBanner : bannerSetting.heightBanner);
  }, [bannerSetting, below768]);

  // const RenderShowIconMenu = () => {
  //   if (isLayout3) {
  //     return (
  //       <div className={classes.headerItem2}>
  //         {/* {!isAuthen && <div
  //           className={classes.headerItem}
  //           onClick={() => setShowLoginFormModal(true)}
  //         >
  //           <VpnKey />
  //           {
  //             !below768 ? <TranslateMessage id="menu.login" /> : null
  //           }
  //         </div>} */}
  //         {
  //           layoutSettingAll.isFullScreenSearch
  //             ? <IconMenuBarButton/>
  //             : <div className={classes.iconMenu} onClick={() => history.push('/search')}>
  //               <Menu/>
  //             </div>
  //         }
  //       </div>
  //     );
  //   }
  //   if ((viewSettings.contentSearchResultSettingResponse.showSidebar || !isContentDetail) &&
  //       (showSidebar || below768)) {
  //     return (
  //       <div className={classes.headerItem2} onClick={() => { toggleSidebar() }}>
  //         <div className={classes.iconMenu}>
  //           <Menu/>
  //           {
  //             !below768 ? <TranslateMessage id="menu.menu" /> : null
  //           }
  //         </div>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const handleClickLogo = () => {
    setShowTabSearch && setShowTabSearch(false);
    clearContents && clearContents();
    setCacheSearchRequest && setCacheSearchRequest();
    setSearchRequest && setSearchRequest([]);
    setFulltextSearch && setFulltextSearch(null);
    setSearchRequestFullTextSearch && setSearchRequestFullTextSearch([]);
  };

  return (
    <div>
      <Navbar dark expand={true} className="p-0">
        <Grid container alignItems='center' justifyContent='space-between' className={`${classes.header} ${isLayout3 && !below768 ? classes.layout3 : ''}`}>
          {/* Logo */}
          <Grid item xs={4} sm={3} md={2} >
            <Box display="flex" justifyContent="space-between" alignItems="center" flex="1 auto">
              <Box className={classes.logoBox}>
                <Link
                  to={'/'} onClick={() => handleClickLogo()}>
                  {pageLogo && pageLogo !== '' && pageLogo !== 'null'
                    ? (
                      <img className={`${classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
                    )
                    : (
                      <></>
                      // <img className={`${classes.logo}`} width="40px" src={notFoundImage} />
                    )
                  }
                </Link>
                {
                  pageTitle && <Link to={'/'} className={classes.pageTitle}>{pageTitle}</Link>
                }
              </Box>
            </Box>
          </Grid>
          {/* Right Header */}
          <Grid item xs={8} sm={9} md={10}>
            <Box className={`${classes.wrapControl} ${classes.fullWidth}`}>
              {/* PC */}
              {
                !below768 && !isLayout3 &&
                <div className={classes.headerItem} onClick={() => history.push('/home-search')}>
                  <LocalLibrary />
                  <TranslateMessage id="menu.books" />
                </div>
              }
              {
                !below768 && !isOpenType && !isMobileApp && <React.Fragment>
                  {
                    authUser && authUser.id && isAuthen
                      ? (
                        <Collapse isOpen={true} navbar style={{
                          flex: 'unset',
                          alignItems: 'flex-start',
                          display: 'flex',
                          padding: '0 0.5rem',
                          minWidth: 100,
                          justifyContent: 'flex-end',
                          flexGrow: 0
                        }}>
                          <Nav navbar style={{ flexDirection: 'row', width: '100%' }} onClick={() => setIsOpenProfile(!isOpenProfile)}>
                            <UncontrolledDropdown nav inNavbar isOpen={isOpenProfile} className={`${classes.fullWidth}`} >
                              <DropdownToggle style={{ width: '100%' }} id='accountDropdownToggle1' nav caret className={`${isLayout3 ? classes.headerItem3 : classes.headerItem}`}>
                                <div style={{ display: 'flex', flexDirection: isLayout3 ? 'row' : 'column', alignItems: 'center', justifyContent: isLayout3 ? 'flex-end' : 'center', width: '100%' }}>
                                  { isLayout3 && <span className={classes.userName}>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span> }
                                  {
                                    isLayout3
                                      ? <i className={`far fa-user-circle ml-2 ${classes.iconUserCircle} ${isOpenProfile ? classes.accountCircleActive : ''}`}/>
                                      : <AccountCircle className={'ml-2'} />
                                  }
                                  { !isLayout3 && <span>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span> }
                                </div>
                              </DropdownToggle>
                              <DropdownMenu right>
                                {
                                  authUser.roleLevel !== 4 ? (
                                    <React.Fragment>
                                      <DropdownItem className="cs-dropdown-item" onClick={() => {
                                        history.push('/profile');
                                      }}>
                                        <Person className="mr-2" />
                                        <TranslateMessage id="label.profile" />
                                      </DropdownItem>
                                    </React.Fragment>
                                  ) : null
                                }
                                {
                                  showSubscriptionStatus
                                    ? <DropdownItem className="cs-dropdown-item" onClick={() => {
                                      history.push('/subscription');
                                    }}>
                                      <Subscriptions className="mr-2" />
                                      <TranslateMessage id="label.subscription" />
                                    </DropdownItem>
                                    : null
                                }
                                <DropdownItem className="cs-dropdown-item" onClick={handleLogout}>
                                  <ExitToApp className="mr-2" />
                                  {
                                    !below768 ? <TranslateMessage id="label.logout" /> : null
                                  }
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Nav>
                        </Collapse>
                      )
                      : (
                        <>
                          {
                            viewSettings.accountType === OPTION_LOGIN.GAKKEN_ID && viewSettings.isAutoRegisterGakkenAccountAtLogin &&
                            <div
                              className={classes.headerItem}
                              onClick={() => registerGakkenUser()}
                            >
                              <PersonAdd className="mr-2" titleAccess={f({ id: 'menu.registerUser' })}/>
                              {
                                !isLayout3 && <TranslateMessage id="menu.registerUser"/>
                              }
                            </div>
                          }
                          <div
                            className={classes.headerItem}
                            onClick={() => setShowLoginFormModal(true)}
                          >
                            { !isLayout3 && <VpnKey/> }
                            {
                              !below768 ? <TranslateMessage id="menu.login" /> : null
                            }
                          </div>
                        </>

                      )
                  }
                </React.Fragment>
              }

              {/* Mobile */}
              {
                below768 && !isOpenType && isLayout3 && !isMobileApp && <React.Fragment>
                  {
                    authUser && authUser.id && isAuthen
                      ? <Collapse isOpen={true} navbar style={{
                        flex: 'unset',
                        alignItems: 'flex-start',
                        display: 'flex',
                        padding: '0 0.5rem',
                        minWidth: 100,
                        justifyContent: 'flex-end',
                        flexGrow: 0
                      }}>
                        <Nav navbar style={{ flexDirection: 'row', width: '100%' }} onClick={() => setIsOpenProfile(!isOpenProfile)}>
                          <UncontrolledDropdown nav inNavbar isOpen={isOpenProfile} className={`${classes.fullWidth}`} >
                            <DropdownToggle style={{ width: '100%', position: 'none' }} id='accountDropdownToggle2' nav caret className={`${classes.headerItem}`}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                                <span className={isLayout3 ? classes.userName : ''}>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span>
                                {
                                  isLayout3
                                    ? <i className={`far fa-user-circle ml-2 ${classes.iconUserCircle} ${isOpenProfile ? classes.accountCircleActive : ''}`}/>
                                    : <AccountCircle className={'ml-2'} />
                                }
                              </div>
                            </DropdownToggle>
                            <DropdownMenu right style={{ position: 'absolute' }}>
                              {
                                authUser.roleLevel !== 4 ? (
                                  <React.Fragment>
                                    <DropdownItem className="cs-dropdown-item" onClick={() => {
                                      history.push('/profile');
                                    }}>
                                      <Person className="mr-2" />
                                      <TranslateMessage id="label.profile" />
                                    </DropdownItem>
                                  </React.Fragment>
                                ) : null
                              }
                              {
                                showSubscriptionStatus
                                  ? <DropdownItem className="cs-dropdown-item" onClick={() => {
                                    history.push('/subscription');
                                  }}>
                                    <Subscriptions className="mr-2" />
                                    <TranslateMessage id="label.subscription" />
                                  </DropdownItem>
                                  : null
                              }
                              <DropdownItem className="cs-dropdown-item" onClick={handleLogout}>
                                <ExitToApp className="mr-2" />
                                <TranslateMessage id="label.logout" />
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Nav>
                      </Collapse>
                      : (
                        <>
                          {
                            viewSettings.accountType === OPTION_LOGIN.GAKKEN_ID && viewSettings.isAutoRegisterGakkenAccountAtLogin &&
                            <div
                              className={classes.headerItem}
                              onClick={() => registerGakkenUser()}
                            >
                              <PersonAdd className="mr-2" titleAccess={f({ id: 'menu.registerUser' })}/>
                            </div>
                          }
                          <div
                            className={classes.headerItem}
                            onClick={() => setShowLoginFormModal(true)}
                          >
                            <VpnKey titleAccess={f({ id: 'menu.login' })}/>
                          </div>
                        </>

                      )
                  }
                </React.Fragment>
              }

              {/* Show Icon Menu */}
              {
                isLayout3
                  ? <div className={classes.headerItem2}>
                    {/* {!isAuthen && <div
                      className={classes.headerItem}
                      onClick={() => setShowLoginFormModal(true)}
                    >
                      <VpnKey />
                      {
                        !below768 ? <TranslateMessage id="menu.login" /> : null
                      }
                    </div>} */}
                    {
                      layoutSettingAll.isFullScreenSearch
                        ? <IconMenuBarButton/>
                        : <div className={classes.iconMenu} onClick={() => history.push('/search')}>
                          <Search titleAccess={f({ id: 'label.search' })}/>
                          {/* <Menu/> */}
                        </div>
                    }
                  </div>
                  : (
                    (viewSettings.contentSearchResultSettingResponse.showSidebar || !isContentDetail) &&
                      (showSidebar || below768) &&
                      <div className={classes.headerItem2} onClick={() => { toggleSidebar() }}>
                        <div className={classes.iconMenu}>
                          <Menu/>
                          {
                            !below768 ? <TranslateMessage id="menu.menu" /> : null
                          }
                        </div>
                      </div>
                  )
              }
            </Box>
          </Grid>
        </Grid>
      </Navbar>
      {
        !isNotShowBanner &&
        <div style={{ display: 'block', width: '100%', overflow: 'hidden' }}>
          { renderBanner() }
        </div>
      }
      <LoginModal
        isOpen={showLoginFormModal}
        toggle={() => setShowLoginFormModal(false)}
        size="md"
        backdrop={'static'}
      >
        <LoginEndUserForm
          onCancel={() => setShowLoginFormModal(false)}
          onSubmit={handleLoginFormSubmit}
        />
      </LoginModal>
    </div>
  );
};

HomeHeader.defaultProps = {};

HomeHeader.propTypes = {
  location: PropTypes.object,
  sessionActions: PropTypes.object.isRequired,
  companyActions: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  isAuthen: PropTypes.bool,
  pageTitle: PropTypes.any,
  pageLogo: PropTypes.any,
  pageFavicon: PropTypes.any,
  toggleSidebar: PropTypes.func,
  showSidebar: PropTypes.bool,
  showLoginFormModal: PropTypes.bool,
  setShowLoginFormModal: PropTypes.func,
  bannerSetting: PropTypes.any,
  isGoogleLogin: PropTypes.bool,
  isContentDetail: PropTypes.bool,
  setShowTabSearch: PropTypes.any,
  layoutViewPage: PropTypes.number,
  isNotShowBanner: PropTypes.bool,
  setCacheSearchRequest: PropTypes.func,
  setSearchRequest: PropTypes.func,
  setFulltextSearch: PropTypes.func,
  setSearchRequestFullTextSearch: PropTypes.func,
  clearContents: PropTypes.func,
  isMobileApp: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    authUser: getAuthUser(state),
    pageTitle: getPageTitle(state),
    pageLogo: getPageLogo(state),
    pageFavicon: getPageFavicon(state),
    isAuthen: isAuthenticated(state),
    bannerSetting: getBannerSetting(state),
    isGoogleLogin: isGoogleLogin(state),
    isMobileApp: getIsMobileApp(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ sessionActions, companyActions }) => (
        <HomeHeader
          {...props}
          sessionActions={sessionActions}
          companyActions={companyActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
