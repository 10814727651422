import SearchTypes from './SearchTypes';
import { DOMAIN_TYPE, PAGINATION } from '../../configs/AppConfig';

const initialState = {
  tabs: [],
  data: [],
  ftsContents: [],
  IsDataLoad: false,
  IsSearch: false,
  isSearchContent: false,
  pagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  searchQuery: {
    sort: false,
    sort_by: undefined
  },
  ftsPagination: {
    ...PAGINATION,
    totalPages: 0,
    totalRecords: 0
  },
  contentDetail: {},
  IsContentDetailLoad: false,
  isLoadContentDetailError: false,
  searchSetting: {},
  beViewerHost: '',
  contentDetailParams: [],
  settingLogin: undefined,
  pageTitle: '',
  isLoadSetting: true,
  options: {},
  pageLogo: '',
  enablePrint: false,
  bodySetting: {},
  footerSetting: {},
  headerSetting: {},
  inputSetting: {
  },
  contentDetailSetting: {},
  tabSetting: {},
  companyName: '',
  viewPage: 0,
  sidebarSetting: {},
  strictPassword: true,
  googleOption: 'NONE',
  microsoftLoginOption: 'NONE',
  showSidebar: true,
  bannerSetting: {},
  moreLessBackground: '',
  moreLessTextColor: '',
  servicePaths: [],
  selectedTabId: '',
  accountType: '',
  newInformation: '',
  allowForgotPassword: true,
  domainType: DOMAIN_TYPE.PATH,
  isCancelAccount: false,
  searchRequests: null,
  fulltextSearch: null,
  layoutSettingAll: {},
  initialValuesSearch: {},
  idTabActive: null,
  isOpenSearchFullScreen: false,
  isAutoRegisterGakkenAccountAtLogin: true,
  isFulltextSearch: false,
  specialContentsGroupId: null
};

export default function dataReducer (state = initialState, action) {
  const {
    type,
    payload
  } = action;
  let newState = { ...state };
  switch (type) {
    case SearchTypes.GET_DATA_REQUEST:
    case SearchTypes.GET_PUBLIC_DATA_REQUEST:
      newState.IsDataLoad = true;
      newState.fulltextSearch = null;
      if (payload.page === 1 || (payload.pagination && payload.pagination.page === 1)) {
        newState.IsSearch = true;
      }
      if (payload.pagination && (payload.pagination.sort || payload.pagination.sort === 0)) {
        const newSearchQuery = { ...newState.searchQuery };
        newSearchQuery.sort = payload.pagination.sort;
        newSearchQuery.sort_by = payload.pagination.sort_by;
        newState.searchQuery = newSearchQuery;
      }
      return newState;
    case SearchTypes.SEARCH_FULL_TEXT_REQUEST:
      newState.IsDataLoad = true;
      newState.searchRequests = null;
      if (payload.page === 1 || (payload.pagination && payload.pagination.page === 1)) {
        newState.IsSearch = true;
      }
      if (payload.pagination && (payload.pagination.sort || payload.pagination.sort === 0)) {
        const newSearchQuery = { ...newState.searchQuery };
        newSearchQuery.sort = payload.pagination.sort;
        newSearchQuery.sort_by = payload.pagination.sort_by;
        newState.searchQuery = newSearchQuery;
      }
      return newState;
    case SearchTypes.GET_DATA_SUCCESS:
    case SearchTypes.GET_PUBLIC_DATA_SUCCESS:
    case SearchTypes.SEARCH_FULL_TEXT_SUCCESS:
      var pageNumber = payload.pageNumber + 1;
      if (!payload.isScrollPage || pageNumber === 1) {
        newState.data = [...payload.content];
        newState.pagination.totalRecords = payload.totalElements;
        newState.pagination.totalPages = payload.totalPages;
        newState.pagination.size = payload.pageSize || PAGINATION.size;
        newState.pagination.page = pageNumber;
        newState.pagination = { ...newState.pagination };
        if (payload.fulltextSearch) {
          newState.fulltextSearch = payload.fulltextSearch;
          // newState.searchRequests = null;
        }
        if (payload.searchRequests) {
          newState.searchRequests = [...payload.searchRequests];
          // newState.fulltextSearch = null;
        }
      } else if ((!newState.pagination.page || newState.pagination.page < pageNumber) && (payload.pageSize * pageNumber > newState.data.length)) {
        newState.data = [...newState.data, ...payload.content];
        newState.pagination.totalRecords = payload.totalElements;
        newState.pagination.totalPages = payload.totalPages;
        newState.pagination.size = payload.pageSize || PAGINATION.size;
        newState.pagination.page = pageNumber;
        newState.pagination = { ...newState.pagination };
        if (payload.fulltextSearch) {
          newState.fulltextSearch = payload.fulltextSearch;
          // newState.searchRequests = null;
        }
        if (payload.searchRequests) {
          newState.searchRequests = [...payload.searchRequests];
          // newState.fulltextSearch = null;
        }
      }
      newState.specialContentsGroupId = payload.specialContentsGroupId;
      newState.IsDataLoad = false;
      newState.IsSearch = false;
      return newState;
    case SearchTypes.GET_PUBLIC_DATA_ERROR:
      newState.IsDataLoad = false;
      newState.IsSearch = false;
      return newState;
    case SearchTypes.SYNC_CONTENT_SEARCH:
      newState.data = newState.data.map(item => {
        if (item.id === payload.contentId) {
          item.isFavorite = payload.isFavorite;
        }
        return item;
      });
      return newState;
    case SearchTypes.GET_CONTENT_INFO_SUCCESS:
      newState.contentDetail = { ...payload };
      newState.isLoadContentDetailError = false;
      newState.IsContentDetailLoad = false;
      return newState;
    case SearchTypes.GET_CONTENT_INFO_REQUEST:
      newState.isLoadContentDetailError = false;
      newState.IsContentDetailLoad = true;
      return newState;
    case SearchTypes.GET_CONTENT_INFO_ERROR:
      newState.contentDetail = {};
      newState.isLoadContentDetailError = true;
      newState.IsContentDetailLoad = false;
      return newState;
    case SearchTypes.GET_SETTING_REQUEST:
      newState.isLoadSetting = true;
      return newState;
    case SearchTypes.GET_SETTING_SUCCESS:
      newState.accountType = payload.accountType;
      newState.strictPassword = payload.strictPassword;
      newState.allowLoginByIpAddress = payload.loginByIpAddress;
      newState.googleOption = payload.googleOption;
      newState.microsoftLoginOption = payload.microsoftLoginOption;
      newState.googleId = payload.googleId;
      newState.googleAutoLoad = payload.googleAutoLoad;

      newState.titleOfEmailField = payload.titleOfEmailField;
      newState.titleOfPasswordField = payload.titleOfPasswordField;

      newState.searchSetting = { ...payload.searchResultSettingResponse };
      newState.tabs = payload.searchTabResponses ? [...payload.searchTabResponses] : [];
      newState.contentDetailParams = payload.contentDetailParams ? [...payload.contentDetailParams] : [];
      newState.settingLogin = payload.settingLogin;
      newState.pageTitle = payload.titlePage;
      newState.isLoadSetting = false;
      newState.pageLogo = payload.logo;
      newState.pageFavicon = payload.favicon;
      newState.beViewerHost = payload.beViewerHost;

      newState.bodySetting = payload.body;
      newState.footerSetting = payload.footerLinkResponse;
      newState.headerSetting = payload.header;
      newState.inputSetting = payload.input || {};
      newState.companyName = payload.companyName;
      if (!newState.inputSetting.borderColor) {
        newState.inputSetting.borderColor = '#3d993a';
        newState.inputSetting.hoverBorderColor = '#3d993a';
      }
      newState.contentDetailSetting = payload.contentSearchResultSettingResponse;
      newState.tabSetting = payload.tabSettingResponse;
      newState.bannerSetting = payload.bannerResponse;
      newState.servicePaths = payload.paths;

      newState.paths = [...payload.paths];
      newState.newInformation = payload.newInformation;
      newState.allowForgotPassword = payload.activeForgotPassword;
      newState.domainType = payload.domainType;
      newState.isCancelAccount = payload.cancelAccount;
      newState.isAutoRegisterGakkenAccountAtLogin = payload.isAutoRegisterGakkenAccountAtLogin;
      if (payload.layoutSettingResponse) {
        newState.viewPage = payload.layoutSettingResponse.viewPage;

        newState.sidebarSetting = {
          background: payload.layoutSettingResponse.sidebarBackground,
          active: payload.layoutSettingResponse.sidebarHighlight,
          text: payload.layoutSettingResponse.sidebarText,
          textActive: payload.layoutSettingResponse.sidebarTextHighlight,
          barBackground: payload.layoutSettingResponse.barBackground,
          barText: payload.layoutSettingResponse.barText
        };

        newState.moreLessBtnSetting = {
          moreLessBackground: payload.layoutSettingResponse.moreLessBackground,
          moreLessTextColor: payload.layoutSettingResponse.moreLessTextColor
        };

        newState.showSidebar = payload.layoutSettingResponse.showSidebar;
        newState.layoutSettingAll = { ...payload.layoutSettingResponse };
      }
      return newState;
    case SearchTypes.GET_SETTING_ERROR:
      newState.isLoadSetting = false;
      return newState;
    case SearchTypes.GET_OPTIONS_SUCCESS:
      newState.options = { ...payload };
      return newState;
    case SearchTypes.RESET_PAGE:
      newState.data = [];
      newState.ftsContents = [];
      newState.IsDataLoad = false;
      newState.IsSearch = false;
      newState.pagination = {
        ...PAGINATION,
        totalPages: 0,
        totalRecords: 0
      };
      newState.ftsPagination = {
        ...PAGINATION,
        totalPages: 0,
        totalRecords: 0
      };
      newState.contentDetail = {};
      newState.IsContentDetailLoad = false;
      newState.isSearchContent = false;
      return newState;
    case SearchTypes.SYNC_CONTENT_DETAIL:
      if (newState.contentDetail.id === payload.contentId) {
        newState.contentDetail.isFavorite = payload.isFavorite;
      }
      newState.contentDetail = { ...newState.contentDetail };
      return newState;
    case SearchTypes.SELECTED_TAB_ID:
      newState.selectedTabId = payload;
      return newState;
    case SearchTypes.STATE_SEARCH_SET_INITIAL_VALUES:
      if (payload) {
        newState.initialValuesSearch = { ...payload };
      }
      return newState;
    case SearchTypes.STATE_SEARCH_SET_ID_TAB_SEARCH:
      newState.idTabActive = payload;
      return newState;
    case SearchTypes.STATE_SEARCH_SET_OPEN_SEARCH_FULL_SCREEN:
      newState.isOpenSearchFullScreen = payload;
      return newState;
    case SearchTypes.SET_IS_FULL_TEXT_SEARCH:
      newState.isFulltextSearch = payload;
      if (payload === false) {
        newState.fulltextSearch = null;
      }
      return newState;
    default:
      newState = state;
      break;
  }
  return newState;
}
