import SessionTypes from './SessionTypes';

export function login (payload) {
  return {
    type: SessionTypes.LOG_IN_REQUEST,
    payload: payload
  };
}

export function logout () {
  return {
    type: SessionTypes.LOG_OUT
  };
}

export function cacheRoutePath () {
  return {
    type: SessionTypes.CACHE_PREV_LOGIN_ROUTE
  };
}

export function getAuthInfo (data) {
  return {
    type: SessionTypes.GET_AUTH_INFO_REQUEST,
    payload: data
  };
}

export function clearAuthInfo () {
  return {
    type: SessionTypes.CLEAR_AUTH
  };
}

export function clearToken () {
  return {
    type: SessionTypes.CLEAR_TOKEN
  };
}

export function setCallstartAppWithCustomAuth (payload) {
  return {
    type: SessionTypes.CALL_START_APP_WITH_CUSTOM_AUTH,
    payload: payload
  };
}
