const _prefix = '@session/';

export default {
  CACHE_PREV_LOGIN_ROUTE: _prefix + 'CACHE_PREV_LOGIN_ROUTE',
  LOG_IN_REQUEST: _prefix + 'LOGIN_REQUEST',
  LOG_IN_SUCCESS: _prefix + 'LOGIN_SUCCESS',
  LOG_IN_ERROR: _prefix + 'LOGIN_ERROR',
  REAUTHORIZE: _prefix + 'REAUTHORIZE', // throw when API return 401
  RESTORE_SESSION_SUCCESS: _prefix + 'RESTORE_SESSION_SUCCESS', // validate session success
  RESTORE_SESSION_FAILURE: _prefix + 'RESTORE_SESSION_FAILURE', // validate session failure
  LOG_OUT: _prefix + 'LOG_OUT',
  LOG_OUT_SUCCESS: _prefix + 'LOG_OUT_SUCCESS',
  GET_AUTH_INFO_REQUEST: _prefix + 'GET_AUTH_INFO_REQUEST',

  LOG_IN_BY_IP_REQUEST: _prefix + 'LOG_IN_BY_IP_REQUEST',
  AUTO_LOG_IN_BY_IP_REQUEST: _prefix + 'AUTO_LOG_IN_BY_IP_REQUEST',
  LOG_IN_BY_IP_SUCCESS: _prefix + 'LOG_IN_BY_IP_SUCCESS',

  ALLOW_GAKKEN_TERMS_REQUEST: _prefix + 'ALLOW_GAKKEN_TERMS_REQUEST',
  SHOW_GAKKEN_MY_PAGE: _prefix + 'SHOW_GAKKEN_MY_PAGE',
  CLEAR_AUTH: _prefix + 'CLEAR_AUTH',
  CLEAR_TOKEN: _prefix + 'CLEAR_TOKEN',
  CALL_START_APP_WITH_CUSTOM_AUTH: _prefix + 'CALL_START_APP_WITH_CUSTOM_AUTH'

};
