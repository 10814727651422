/**
 * localStorage ~5MB, saved for infinity or until the user manually deletes it.
 * sessionStorage ~5MB, saved for the life of the CURRENT TAB
 */
import { PUBLIC_PAGE } from 'configs/AppConfig';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const pathname = window.location.pathname;
const servicePath = pathname.split('/')[1];
const isPublicViewer = PUBLIC_PAGE.find(item => item === servicePath);

const cookieOption = {
  path: `/${isPublicViewer ? '' : servicePath}`
};

function setCookie (name, value, options = {}) {
  cookies.set(name, value, {
    ...cookieOption,
    ...options
  });
}

function getCookie (name, options = {}) {
  const data = cookies.get(name, options);
  return data && data !== 'null' ? data : null;
}

async function removeCookie (name, options = {}) {
  setCookie(name, null, options);
  cookies.remove(name, { ...cookieOption, ...options });
}

function setLocalItem (name, value) {
  localStorage.setItem(name, value);
}

function setLocalObject (name, obj) {
  setLocalItem(name, JSON.stringify(obj));
}

function getLocalItem (name) {
  return localStorage.getItem(name);
}

function getLocalObject (name) {
  const data = getLocalItem(name);
  let parsedJSON;
  try {
    parsedJSON = JSON.parse(data);
  } catch (error) {
    console.error('Invalid JSON format: ' + error);
    return null;
  }
  return parsedJSON;
}

function removeLocalItem (name) {
  localStorage.removeItem(name);
}

function setSessionItem (name, value) {
  sessionStorage.setItem(name, value);
}

function setSessionObject (name, obj) {
  setSessionItem(name, JSON.stringify(obj));
}

function getSessionItem (name) {
  return sessionStorage.getItem(name);
}

function getSessionObject (name) {
  return JSON.parse(getSessionItem(name));
}

function removeSessionItem (name) {
  sessionStorage.removeItem(name);
}

export default {
  setLocalItem,
  getLocalItem,
  setLocalObject,
  getLocalObject,
  removeLocalItem,
  setSessionItem,
  getSessionItem,
  setSessionObject,
  getSessionObject,
  removeSessionItem,
  setCookie,
  getCookie,
  removeCookie
};
