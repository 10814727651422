import SearchTypes from './SearchTypes';

export function getData (data) {
  return {
    type: SearchTypes.GET_DATA_REQUEST,
    payload: data
  };
}

export function getPublicData (data) {
  return {
    type: SearchTypes.GET_PUBLIC_DATA_REQUEST,
    payload: data
  };
}

export function loadDetail (data) {
  return {
    type: SearchTypes.GET_CONTENT_INFO_REQUEST,
    payload: data
  };
}

export function loadOptions (data) {
  return {
    type: SearchTypes.GET_OPTIONS_REQUEST,
    payload: data
  };
}

export function resetSearchPage (data) {
  return {
    type: SearchTypes.RESET_PAGE,
    payload: data
  };
}

export function searchFullText (data) {
  return {
    type: SearchTypes.SEARCH_FULL_TEXT_REQUEST,
    payload: data
  };
}

export function selectedSearchTab (data) {
  return {
    type: SearchTypes.SELECTED_TAB_ID,
    payload: data
  };
}

// export function setTabSearchMap (data) {
//   return {
//     type: SearchTypes.STATE_SEARCH_SET_TAB_SEARCH_MAP,
//     payload: data
//   };
// }
export function setInitialValuesSearch (data) {
  return {
    type: SearchTypes.STATE_SEARCH_SET_INITIAL_VALUES,
    payload: data
  };
}
export function setIdTabSearch (data) {
  return {
    type: SearchTypes.STATE_SEARCH_SET_ID_TAB_SEARCH,
    payload: data
  };
}
export function setOpenSearchFullScreen (data) {
  return {
    type: SearchTypes.STATE_SEARCH_SET_OPEN_SEARCH_FULL_SCREEN,
    payload: data
  };
}

export function setIsFullTextSearch (data) {
  return {
    type: SearchTypes.SET_IS_FULL_TEXT_SEARCH,
    payload: data
  };
}
